import { ReadParameter, GetSlug, Customer } from "../../business";
import {
  METHODS,
  defaultHeaders,
  RESPONSE_CODES,
  API_ENDPOINT,
  FetchResponseType,
} from "../../constants";
import { authHeaders, getSessionToken } from "../auth/AuthServices";
import {
  CreditOrderResponse,
  OrderProductsCocina,
  OrderResponseCocina,
  OrderResponseExternal,
  OrderResponseMesa,
} from "../ResponseModel";
import { SendIssue } from "../restaurants/RestaurantServices";

const enum RESOURCES {
  orders = "orders",
  ordersRestaurant = "orders/restaurants",
}

function packedHeaders() {
  return { ...defaultHeaders, "FDS-Auth": getSessionToken(), slug: GetSlug() };
}

export interface CategoryAddon {
  id: number;
  category_id: number;
  name: string;
  price: number;
  add: boolean;
}

export interface CartProduct {
  id: number;
  price: number;
  amount: number;
  observation: string;
  name: string;
  addons?: CategoryAddon[];
  delay: number;
  can_delay: boolean;
  menu_category_id?: number;
}

export interface MenuCartProduct {
  id: number;
  price: number;
  amount: number;
  name: string;
  menu_category_id: number;
  supplement: number;
  observation: string;
}

export interface Cart {
  products: CartProduct[];
}
export interface MenuCart {
  products: MenuCartProduct[];
  menu_id: number;
  menu_price: number;
}

/**
 *
 * @param domain
 * @param tables
 * @param view
 * @param delivery
 * @param takeaway
 * @returns
 */
export const GetKDSOrders = async (
  domain: string,
  tables: string[],
  view: string,
  delivery: boolean,
  takeaway: boolean
) => {
  //>> Composición de URL
  const resource = `restaurants/${ReadParameter("slug")}/kds`;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<OrderResponseCocina[]>;
  let DefaultResponse: OrderResponseCocina[] = [];

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: packedHeaders(),
    // body: JSON.stringify({
    //   domain: domain,
    //   tables: tables,
    //   view: view,
    //   delivery: delivery,
    //   takeaway: takeaway,
    // }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const GetOrder = async (
  order_id: number | string,
  view: string,
  created_at: string,
  hierarchy?: string
) => {
  //>> Composición de URL
  const resource = RESOURCES.orders;
  const url = API_ENDPOINT + resource + "/" + order_id + "/filter";

  //>> Respuesta
  type Response = FetchResponseType<OrderProductsCocina>;
  let DefaultResponse: OrderProductsCocina;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({
      view: view,
      hierarchy: hierarchy,
      created_at: created_at,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        console.error(JSON.stringify(res));
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Productos de pedidos
 */
export const GetAllOrdersDelivery = async () => {
  //>> Composición de URL
  const resource = RESOURCES.ordersRestaurant;
  const url = API_ENDPOINT + resource + "/" + "delivery";

  //>> Respuesta
  type Response = FetchResponseType<OrderResponseExternal[]>;
  let DefaultResponse: OrderResponseExternal[] = [];

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const GetAllOrdersTakeaway = async () => {
  //>> Composición de URL
  const resource = RESOURCES.ordersRestaurant;
  const url = API_ENDPOINT + resource + "/" + "takeaway";

  //>> Respuesta
  type Response = FetchResponseType<OrderResponseExternal[]>;
  let DefaultResponse: OrderResponseExternal[] = [];

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS)
        throw new Error("No se ha podido conectar con el servidor");

      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const GetOrderProductsHist = async (order_id: number | string) => {
  //>> Composición de URL
  const resource = RESOURCES.orders;
  const url = API_ENDPOINT + resource + "/" + order_id;

  //>> Respuesta
  type Response = FetchResponseType<OrderResponseMesa>;
  let DefaultResponse: OrderResponseMesa;

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        console.error(JSON.stringify(res));
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const DeleteOrder = async (order_id: number | string) => {
  //>> Composición de URL
  const resource = RESOURCES.orders;
  const url = API_ENDPOINT + resource + "/" + order_id;

  //>> Respuesta
  type Response = FetchResponseType<OrderProductsCocina>;
  let DefaultResponse: OrderProductsCocina;

  const result: Response = await fetch(url, {
    method: METHODS.DELETE,
    headers: authHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        console.error(JSON.stringify(res));
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Obtiene todos los pedidos de un restaurante
 */
export const AutoPrintDaemon = async () => {
  //>> Composición de URL
  const resource = "daemon/orders";
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = {
    result_code: number;
    response: OrderResponseCocina[];
    print_delivery: number;
    print_takeaway: number;
    messages: { text: string; id: number }[];
  };
  let DefaultResponse: OrderResponseCocina[] = [];

  if (
    getSessionToken() === "" ||
    getSessionToken() == undefined ||
    getSessionToken() == null
  )
    SendIssue(
      "SESSION_TOKEN",
      "OrderServices::AutoPrintDaemon - No session token was found for " +
        GetSlug()
    );

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS)
        throw new Error("No se ha podido conectar con el servidor");

      return res;
    })
    .catch((error) => {
      console.error(error);
      return {
        result_code: 400,
        response: DefaultResponse,
        print_delivery: 0,
        print_takeaway: 0,
        messages: [],
      };
    });

  return result;
};

/**
 *
 * @param order_type
 * @returns
 */
export const GetExternalSummary = async (order_type: string) => {
  //>> Composición de URL
  const resource = `daemon/${order_type}/summary`;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = {
    result_code: number;
    response: {
      id: number;
      product_id: number;
      amount: string;
      observation: string;
      name: string;
      state: string;
      price: number;
      category_id: number;
      category: string;
    }[];
  };
  let DefaultResponse: {
    id: number;
    product_id: number;
    amount: string;
    observation: string;
    name: string;
    state: string;
    price: number;
    category_id: number;
    category: string;
  }[] = [];

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return {
        result_code: 400,
        response: DefaultResponse,
        print_delivery: 0,
        print_takeaway: 0,
        messages: [],
      };
    });

  return result;
};

export const CreateOrder = async (product_data: any[]) => {
  //>> Composición de URL
  const resource = RESOURCES.orders + "/fast_checkout";
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<{
    id: number;
    total_price: string;
    menus: string[];
    products: {
      id: number;
      product_id: string;
      amount: string;
      observation: string;
      hierarchy: string;
      price: string;
      name: string;
      printed: boolean;
    }[];
  }>;
  let DefaultResponse: Response;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: authHeaders(),
    body: JSON.stringify({
      products: product_data,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return DefaultResponse;
    });

  return result;
};

export const CloseOrderById = async (order_id: number | string) => {
  //>> Composición de URL
  const resource = RESOURCES.orders;
  const url = API_ENDPOINT + resource + "/" + order_id + "/checkout";

  //>> Respuesta
  type Response = FetchResponseType<string>;
  let DefaultResponse: string;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: authHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        console.error(JSON.stringify(res));
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const CreateExternalOrder = async (
  type: string,
  cart: Cart,
  menus: MenuCart[],
  hour: string,
  name: string,
  phone: string,
  email: string,
  cif: string,
  city: string,
  zip_code: string,
  date: any,
  address: string,
  payment_method: string
) => {
  //>> Composición de URL
  const resource = RESOURCES.orders + "/external";
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<OrderResponseExternal[]>;
  let DefaultResponse: OrderResponseExternal[] = [];

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: authHeaders(),
    body: JSON.stringify({
      type: type,
      products: cart.products,
      menus: menus,
      hour: hour,
      name: name,
      phone: phone,
      email: email,
      cif: cif,
      city: city,
      zip_code: zip_code,
      date: date,
      address: address,
      payment_method: payment_method,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const GetAllCreditOrders = async (payed: boolean) => {
  //>> Composición de URL
  const resource = `restaurants/${ReadParameter("slug")}/${
    payed ? "payed-credit-orders" : "credit-orders"
  }`;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<CreditOrderResponse[]>;
  let DefaultResponse: CreditOrderResponse[] = [];

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: authHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const PayCreditOrders = async (
  credits: number[],
  payment_method: string,
  total_amount: number
) => {
  //>> Composición de URL
  const resource = `restaurants/${ReadParameter("slug")}/credit-orders/pay`;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse = null;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: authHeaders(),
    body: JSON.stringify({
      credits: credits,
      payment_method: payment_method,
      total_amount: total_amount,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const UpdateCreditCustomer = async (
  orderId: number,
  creditId: number,
  customer: {
    name: string;
    cif: string;
    email: string;
    address: string;
    phone: string;
  }
) => {
  //>> Composición de URL
  const resource = `restaurants/${ReadParameter(
    "slug"
  )}/credit-orders/${creditId}`;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<CreditOrderResponse[]>;
  let DefaultResponse: CreditOrderResponse[] = [];

  const result: Response = await fetch(url, {
    method: "PATCH",
    headers: authHeaders(),
    body: JSON.stringify({ order_id: orderId, ...customer }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const GetPrintableInvoice = async (invoice_id: number | string) => {
  //>> Composición de URL
  const resource = `restaurants/${GetSlug()}/invoices/${invoice_id}`;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<OrderResponseMesa>;
  let DefaultResponse: OrderResponseMesa;

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *
 * @param invoice_id
 * @param customer
 * @returns
 */
export const UpdateInvoiceMetadata = async (
  invoice_id: number | string,
  customer: Customer,
  paymentMethod: string
) => {
  //>> Composición de URL
  const resource = `restaurants/${GetSlug()}/invoices/${invoice_id}`;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: "PATCH",
    headers: packedHeaders(),
    body: JSON.stringify({ customer: customer, payment_method: paymentMethod }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};
