import {
  Customer,
  GetSelectedSpace,
  OrderProduct,
  ReadParameter,
} from "../../business";
import {
  API_ENDPOINT,
  RESPONSE_CODES,
  METHODS,
  FetchResponseType,
} from "../../constants";
import { authHeaders, getSessionToken } from "../auth/AuthServices";
import { MenuCart } from "../orders/OrderServices";
import { OrderResponseMesa, TableStateResponse } from "../ResponseModel";

const enum RESOURCES {
  tables = "tables",
  products = "products",
  payments = "payments",
  menus = "menus",
}

export async function GetTablesStates(
  space?: string | number,
  ignore_cache?: boolean
) {
  //>> Composición de URL
  const resource = `spaces/${space ? space : GetSelectedSpace()}/tables`;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<TableStateResponse[]>;
  let DefaultResponse: TableStateResponse[] = [];

  if (
    getSessionToken() === "" ||
    getSessionToken() == undefined ||
    getSessionToken() == null
  )
    return {
      result_code: 400,
      response: DefaultResponse,
    };

  if (!ignore_cache)
    if (
      GetSelectedSpace() === "" ||
      GetSelectedSpace() == undefined ||
      GetSelectedSpace() == null
    )
      return {
        result_code: 400,
        response: DefaultResponse,
      };

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: authHeaders(),
    credentials: "same-origin",
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
}

export const GetTableProducts = async (table_id: string) => {
  //>> Composición de URL
  const resource = RESOURCES.tables + "/" + table_id + "/" + RESOURCES.products;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<OrderResponseMesa>;
  let DefaultResponse: OrderResponseMesa;

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: authHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *
 * @param table_id
 * @param product_data
 * @param people_number
 * @param comment
 * @param menus
 * @param should_print
 * @returns
 */
export const AddProductsToTableOrder = async (
  table_id: string,
  product_data: any[],
  people_number: number,
  comment: string,
  menus: MenuCart[],
  should_print: boolean
) => {
  //>> Composición de URL
  const resource = RESOURCES.tables + "/" + table_id + "/" + RESOURCES.products;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<OrderResponseMesa>;
  let DefaultResponse: OrderResponseMesa;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: authHeaders(),
    body: JSON.stringify({
      products: product_data,
      people_number: people_number,
      comment: comment,
      menus: menus,
      should_print: should_print,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *
 * @param order_id
 * @returns
 */
export const CancelOrder = async (order_id: string | number) => {
  //>> Composición de URL
  const resource = "preorders/" + order_id;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: METHODS.DELETE,
    headers: authHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS)
        throw new Error("No se ha podido conectar con el servidor");

      return res;
    })
    .catch((error) => {
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *
 * @param order_id
 * @param user_id
 * @param user_name
 * @param people_number
 * @param comment
 * @param customer_name
 * @returns
 */
export const UpdateOrderData = async (
  order_id: string | number,
  user_id: string | number,
  user_name: string,
  people_number: number,
  comment: string,
  customer: Customer | null
) => {
  //>> Composición de URL
  const resource = "orders/" + order_id + "/metadata";
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: authHeaders(),
    body: JSON.stringify({
      user_id: user_id,
      user_name: user_name,
      people_number: people_number,
      general_comment: comment,
      customer: customer,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS)
        throw new Error("No se ha podido conectar con el servidor");
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const AddPaymentToTableOrder = async (
  table_id: string,
  order_id: string | number,
  amount: number,
  payment_method: string,
  products: { id: number; amount: number }[],
  menus: { id: number; amount: number }[],
  tip?: number,
  customer?: Customer
) => {
  //>> Composición de URL
  const resource = RESOURCES.tables + "/" + table_id + "/" + RESOURCES.payments;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<{
    state: "OPEN" | "CLOSED";
    invoiceId: string;
    products: OrderProduct[];
    menus: {
      id: number;
      amount: number;
      menu_id: string;
      price: number;
      name: string;
      payed_amount: number;
      products: {
        id: number;
        name: string;
        amount: string;
        observation: string;
        comment: string | null;
      }[];
    }[];
  }>;
  let DefaultResponse: {
    state: "OPEN" | "CLOSED";
    invoiceId: string;
    products: OrderProduct[];
    menus: {
      id: number;
      amount: number;
      menu_id: string;
      price: number;
      name: string;
      payed_amount: number;
      products: {
        id: number;
        name: string;
        amount: string;
        observation: string;
        comment: string | null;
      }[];
    }[];
  };

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: authHeaders(),
    body: JSON.stringify({
      order_id: order_id,
      amount: amount,
      payment_method: payment_method,
      products: products,
      menus: menus,
      tip: tip,
      customer: customer,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS)
        throw new Error("No se ha podido conectar con el servidor");
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *
 * @param order_id
 * @param customer
 * @returns
 */
export const AddOrderToCredit = async (
  order_id: string | number,
  customer: {
    name: string;
    cif: string;
    phone: string;
    address: string;
    email: string;
  }
) => {
  //>> Composición de URL
  const resource = `restaurants/${ReadParameter(
    "slug"
  )}/orders/${order_id}/credit`;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: authHeaders(),
    body: JSON.stringify({
      ...customer,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *
 * @param order_id
 * @param email
 * @returns
 */
export const SendInvoiceCopy = async (
  order_id: string | number,
  email: string,
  customer: Customer
) => {
  //>> Composición de URL
  const resource = `restaurants/${ReadParameter(
    "slug"
  )}/orders/${order_id}/send_invoice`;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: authHeaders(),
    body: JSON.stringify({
      email: email,
      customer: customer,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const ChangeTableOrder = async (
  table_id: string,
  new_table_id: string
) => {
  //>> Composición de URL
  const resource = RESOURCES.tables + "_permute";
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: authHeaders(),
    body: JSON.stringify({
      table_id: table_id,
      new_table_id: new_table_id,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *
 * @param table_id
 * @param destination_table_id
 * @returns
 */
export const UnifyTables = async (
  table_id: string,
  destination_table_id: string
) => {
  //>> Composición de URL
  const resource = RESOURCES.tables + "_unify";
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: authHeaders(),
    body: JSON.stringify({
      table_id: table_id,
      new_table_id: destination_table_id,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS)
        throw new Error("No se ha podido conectar con el servidor");
      return res;
    })
    .catch((error) => {
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *
 * @param tables
 * @returns
 */
export const UpdateTablesGrid = async (
  tables: TableStateResponse[],
  viewMode: "MAP" | "MODERN"
) => {
  //>> Composición de URL
  const resource = "grid/" + RESOURCES.tables;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<string>;
  let DefaultResponse: string;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: authHeaders(),
    body: JSON.stringify({
      tables: tables,
      view_mode: viewMode,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *
 * @param table_id
 * @param products
 * @param menus
 * @returns
 */
export const UpdateTableProducts = async (
  table_id: string,
  products: OrderProduct[],
  menus: { id: number; amount: number; price: number }[]
) => {
  //>> Composición de URL
  const resource = RESOURCES.tables + "/" + table_id + "/" + RESOURCES.products;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: authHeaders(),
    body: JSON.stringify({
      products: products.map((pr) => {
        return { id: pr.id, amount: pr.amount, price: pr.price };
      }),
      menus: menus,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *
 * @param products
 * @returns
 */
export const SetProductsToPrint = async (products: number[]) => {
  //>> Composición de URL
  const resource = `${RESOURCES.tables}/${RESOURCES.products}/reprint`;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: authHeaders(),
    body: JSON.stringify({
      products: products,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS)
        throw new Error("No se ha podido conectar con el servidor");
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};
